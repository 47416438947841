export const lightTheme = {
  background: '#F8FAF9',
  backgroundHighlight: '#DADDDF',
  nonFocus: '#9DA1A4',
  main: '#71787E',
  mainBright: '#585F65',
  highlight: '#262D33',
  lightGreen: '#AAD7B0',
  green: '#50BFA6',
  darkGreen: '#009D95',
  turquoise: '#007281',
  blue: '#00406D',
  yellow: '#E7AE0A',
  orange: '#D15503',
  red: '#A81000',
  // basic highlight colours
  bRed: '#FF0014',
  bOrange: '#FF6E00',
  bYellow: '#F3A900',
  bGreen: '#009B00',
  bCyan: '#009C94',
  bBlue: '#008EFF',
  bPurple: '#BF2BFF',
  bMagenta: '#F5006C',
};

export const darkTheme = {
  background: '#101921',
  backgroundHighlight: '#262D33',
  nonFocus: '#585F65',
  main: '#81878B',
  mainBright: '#9DA1A4',
  highlight: '#DADDDF',
  // basic highlight colours
  bRed: '#FF0014',
  bOrange: '#FF6E00',
  bYellow: '#F3A900',
  bGreen: '#009B00',
  bCyan: '#009C94',
  bBlue: '#008EFF',
  bPurple: '#BF2BFF',
  bMagenta: '#F5006C',
};
